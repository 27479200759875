import { QueryStatus } from 'react-query'
import { RestaurantStatusTable } from './RestaurantStatusTable'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import {
  LookupInfoIcon,
  SearchIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  InvitationStatus,
  RestaurantInfoData
} from '../types/RestaurantInfoData'

export const RestaurantUserLookupStateContainer = ({
  getRestaurantInfoStatus,
  getInvitationStatusStatus,
  restaurantData,
  invitationStatus,
  userGuid
}: {
  getRestaurantInfoStatus: QueryStatus
  getInvitationStatusStatus: QueryStatus
  restaurantData?: RestaurantInfoData[]
  invitationStatus?: InvitationStatus
  userGuid: string
}) => {
  const getContainerState = (
    getRestaurantInfoStatus: QueryStatus,
    getInvitationStatusStatus: QueryStatus,
    restaurantData?: RestaurantInfoData[],
    invitationStatus?: InvitationStatus
  ) => {
    if (invitationStatus && restaurantData?.[0]) {
      return 'success'
    } else if (
      getRestaurantInfoStatus === 'error' ||
      getInvitationStatusStatus === 'error'
    ) {
      return 'error'
    } else if (restaurantData && !restaurantData?.[0]) {
      return 'empty'
    } else if (
      getRestaurantInfoStatus === 'loading' ||
      getInvitationStatusStatus === 'loading'
    ) {
      return 'loading'
    } else {
      return 'idle'
    }
  }

  return (
    <div className='md:h-80 flex text-default'>
      {
        {
          loading: <LoadingState />,
          error: <ErrorState />,
          idle: <IdleState />,
          empty: <EmptyContainerState />,
          success: (
            <RestaurantStatusTable
              invitationStatus={invitationStatus as InvitationStatus}
              restaurantData={restaurantData?.[0] as RestaurantInfoData}
              userGuid={userGuid}
            />
          )
        }[
          getContainerState(
            getRestaurantInfoStatus,
            getInvitationStatusStatus,
            restaurantData,
            invitationStatus
          )
        ]
      }
    </div>
  )
}

const LoadingState = () => (
  <div className='self-center flex w-full justify-center'>
    <MerryGoRound size={MerryGoRound.Size.md} />
  </div>
)

const ErrorState = () => (
  <EmptyState
    className='items-center'
    icon={<WarningOutlineIcon accessibility='decorative' />}
    title={'An error occurred'}
  >
    An error occurred while fetching the data. Please try again.
  </EmptyState>
)

const IdleState = () => (
  <EmptyState
    className='items-center'
    icon={<SearchIcon accessibility='decorative' />}
    title={'Enter a restaurant GUID'}
  >
    Enter a restaurant GUID to look up the users invitation status at that
    restaurant.
  </EmptyState>
)

const EmptyContainerState = () => (
  <EmptyState
    className='items-center'
    icon={<LookupInfoIcon accessibility='decorative' />}
    title={'Could not find restaurant'}
  >
    No restaurant couldn't be found for the provided GUID. Please check the GUID
    and try again.
  </EmptyState>
)
