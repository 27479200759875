import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { useTranslation } from '@local/translations'

export interface RevokeRestaurantAccessModalProps {
  isOpen: boolean
  isLoading: boolean
  onConfirm: () => void
  onCancel: () => void
  selectedRestaurants: string[]
}

export const RevokeRestaurantAccessModal = ({
  isOpen,
  isLoading,
  onConfirm,
  onCancel,
  selectedRestaurants
}: RevokeRestaurantAccessModalProps) => {
  const size = useScreenSize()
  const { t } = useTranslation()

  return (
    <>
      {isOpen && (
        <Modal size={Modal.Size.xl} isOpen={isOpen}>
          <Modal.Header>
            {t('restaurantAccess.revokeAccess.modal.title')}
          </Modal.Header>
          <Modal.Body className='pb-4 md:pb-6'>
            <p>{t('restaurantAccess.revokeAccess.modal.message')}</p>
            <ul className='list-disc pl-5 pt-4'>
              {selectedRestaurants.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup
              className='w-full md:w-fit'
              direction={
                size < ScreenSize.MD
                  ? ButtonGroup.direction.vertical
                  : ButtonGroup.direction.horizontal
              }
            >
              <Button
                variant={Button.Variant?.link}
                disabled={isLoading}
                onClick={onCancel}
              >
                {t('restaurantAccess.revokeAccess.modal.cancel')}
              </Button>
              <Button
                variant={Button.Variant?.destructive}
                disabled={isLoading}
                onClick={onConfirm}
              >
                {t('restaurantAccess.revokeAccess.modal.confirm')}
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
