import { Criterion } from '../api/user'
import { criteriaValues } from '../components/SearchUserForm/schema'

export const getInitialValuesFromParam = () => {
  const params = new URLSearchParams(window.location.search)

  let search = ''
  const phone = { countryCode: '', nationalNumber: '' }
  let criterion = 'email' as Criterion

  // Use the first valid param
  params.forEach((paramValue, paramName) => {
    const validParamsFound = search && criterion
    if (!validParamsFound && criteriaValues.includes(paramName as Criterion)) {
      search = paramValue
      criterion = paramName as Criterion
    }
  })

  return {
    search,
    phone,
    criterion
  }
}
