import { ApiError } from '../types/ApiError'
import {
  METHOD_POST,
  ROUTE_USERS_ADMIN,
  PARTIAL_ROUTE_RECOMPUTE_ACCESS
} from './constants'

export const RECOMPUTE_ACCESS_KEY = 'recompute_access_key'

export async function recomputeAdminAccess(userGuid: string) {
  const recomputeAccessRoute = `${ROUTE_USERS_ADMIN}/${userGuid}${PARTIAL_ROUTE_RECOMPUTE_ACCESS}`
  const response = await fetch(recomputeAccessRoute, {
    method: METHOD_POST,
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({})
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
