import { ApiError } from '../types/ApiError'
import { METHOD_DELETE, ROUTE_USERS_CUSTOMERS } from './constants'

interface MfaResponse {
  status: boolean
}

function mfaRoute(userGuid: string) {
  return `${ROUTE_USERS_CUSTOMERS}/${userGuid}/mfa`
}

export const DISABLE_MFA_KEY = 'disable_mfa_key'
export const RESET_MFA_KEY = 'reset_mfa_key'

export async function getMfaState(userGuid: string) {
  const response = await fetch(mfaRoute(userGuid))

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return (await response.json()) as MfaResponse
}

export async function disableMfa(userGuid: string) {
  const response = await fetch(mfaRoute(userGuid), {
    method: METHOD_DELETE
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}

export async function getMandatoryMfaReasons(userGuid: string) {
  const response = await fetch(mfaRoute(userGuid) + '/enforce/reasons')

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return (await response.json()) as string[]
}

export async function resetMfa(userGuid: string) {
  const response = await fetch(mfaRoute(userGuid) + '/reset', {
    method: METHOD_DELETE
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
