import {
  CheckCircleSelectedFillIcon,
  CheckCircleUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import { permissionOn } from '../../utils/permissionUtil'

export interface ReadonlyPermissionProps {
  permissions: bigint
  position: number
}

export function ReadonlyPermission({
  permissions,
  position
}: ReadonlyPermissionProps) {
  if (permissionOn(permissions, position)) {
    return (
      <CheckCircleSelectedFillIcon
        accessibility='decorative'
        size='md'
        className='text-success flex justify-center'
        testId={'permissions-selected-' + position}
      />
    )
  } else {
    return (
      <CheckCircleUnselectedIcon
        accessibility='decorative'
        size='md'
        className='text-gray-110 flex justify-center'
        testId={'permissions-unselected-' + position}
      />
    )
  }
}
