import { InfoTooltip, TooltipTextLayout } from '@toasttab/buffet-pui-tooltip'

export interface TextWithOptionalTooltipProps {
  text: string
  tooltip?: string | null
}
export function TextWithOptionalTooltip({
  text,
  tooltip
}: TextWithOptionalTooltipProps) {
  //TODO: The tooltip causes a gap to the right of the page content for some reason causing a double scroll
  return (
    <>
      {tooltip ? (
        <TooltipTextLayout
          className='min-w-max'
          helperIconButton={<InfoTooltip>{tooltip}</InfoTooltip>}
        >
          {text}
        </TooltipTextLayout>
      ) : (
        text
      )}
    </>
  )
}
