import { useTranslation } from '@local/translations'
import { DISABLE_MFA_KEY, disableMfa } from '../../api/mfa'
import { UserViewAction } from './UserViewAction'
import { CloseCircleIcon } from '@toasttab/buffet-pui-icons'

interface DisableMfaProps {
  userGuid: string
}

export function DisableMfa({ userGuid }: DisableMfaProps) {
  const { t } = useTranslation()
  const DisableMfaModalBody = () => (
    <>
      <p>{t('actions.disableMfa.content.disablingWillResultIn')}</p>
      <ul className='list-inside list-disc px-2'>
        <li>{t('actions.disableMfa.content.noPrompt')}</li>
        <li>{t('actions.disableMfa.content.voidPreviousMfa')}</li>
        <li>{t('actions.disableMfa.content.voidPreviousRecoveryCode')}</li>
        <li>{t('actions.disableMfa.content.lessSecure')}</li>
      </ul>
      <p className='mt-4'>{t('actions.disableMfa.content.canBeReenabled')}</p>
    </>
  )

  return (
    <UserViewAction
      actionLabel={t('actions.disableMfa.title')}
      iconElement={<CloseCircleIcon accessibility='decorative' />}
      modalHeader={t('actions.disableMfa.message')}
      modalBodyContent={<DisableMfaModalBody />}
      errorMessage={t('actions.disableMfa.error')}
      successMessage={t('actions.disableMfa.success')}
      userGuid={userGuid}
      mutationKey={DISABLE_MFA_KEY}
      mutationFn={disableMfa}
    />
  )
}
