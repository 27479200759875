import { ApiError } from '../types/ApiError'

export const GET_INVITATION_STATUS = 'get_invitation_status_key'

export async function getInvitationStatus({
  userGuid,
  restaurantGuid
}: {
  userGuid: string
  restaurantGuid: string
}) {
  const invitationStatusRoute = `/api/service/identity-attributes/v1/user-restaurant/${restaurantGuid}/${userGuid}/invitation-status`
  const response = await fetch(invitationStatusRoute)

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    if (body.message === 'USER_NOT_FOUND_AT_RESTAURANT') {
      return body.message
    }
    throw new Error(body.message)
  }
  return await response.json()
}
