import * as React from 'react'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Body, Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { Button } from '@toasttab/buffet-pui-buttons'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import {
  DisableMfa,
  UnverifyIdentity,
  UnverifyPhone,
  UnverifyPhoneAndIdentity
} from '../Actions'
import { Header } from './Header'
import { ID_VERIFICATION_STATUS, User } from '../../api/user'
import { TextWithOptionalTooltip } from '../AdministratorAccess/TextWithOptionalTooltip'
import { useTranslation } from '@local/translations'
import { ResetMfa } from '../Actions/ResetMfa'

interface DataRowProps {
  field: string
  data: React.ReactNode
  children?: React.ReactNode
  fieldTooltipMessage?: string
}

function DataRow({ field, data, children, fieldTooltipMessage }: DataRowProps) {
  return (
    <Row>
      {/* Need align middle as actions are bigger than a normal row */}
      <Cell className='w-1/5'>
        <TextWithOptionalTooltip text={field} tooltip={fieldTooltipMessage} />
      </Cell>
      <Cell className='w-2/5 select-all text-[14px]'>{data}</Cell>
      <Cell className='w-2/5 md:py-0'>{children}</Cell>
    </Row>
  )
}

export interface ViewUserProps {
  user: User
}

export function ViewUser({
  user: {
    firstName,
    lastName,
    email,
    guid,
    adminAccess,
    phoneNumber,
    phoneNumberCountryCode,
    isPhoneNumberVerified,
    hasMfa,
    lastIdVerificationInquiryID,
    lastIdVerificationStatus,
    lastIdVerificationDate,
    createdDate,
    hasBackendAccess,
    deleted,
    deletedDate,
    isMandatoryMfaEnabled,
    mandatoryMfaReasons
  }
}: ViewUserProps) {
  const { t } = useTranslation()
  // Not using `react-table` here since the rows themselves are static, but the
  // cells data is dynamic. Same for actions, it would be tricky since they are
  // different based on rows.
  return (
    <Table valign='middle'>
      <Header />
      <Body>
        <DataRow
          field={t('userDetails.table.firstName.label')}
          data={firstName}
        />
        <DataRow
          field={t('userDetails.table.lastName.label')}
          data={lastName}
        />
        <DataRow
          field={t('userDetails.table.emailAddress.label')}
          data={email}
        />
        <DataRow
          field={t('userDetails.table.dateCreated.label')}
          data={format(new Date(createdDate), Formats.date.long)}
        />
        <DataRow
          field={t('userDetails.table.accountStatus.label')}
          data={getAccountStatus(deleted, deletedDate, hasBackendAccess, t)}
          fieldTooltipMessage={t('userDetails.table.accountStatus.tooltip')}
        />
        <DataRow
          field={t('userDetails.table.guid.label')}
          data={<p className='whitespace-nowrap'>{guid}</p>}
        />
        <DataRow
          field={t('userDetails.table.userType.label')}
          data={
            adminAccess.roles.some((r) => r.startsWith('TOAST_'))
              ? t('userDetails.table.userType.admin')
              : t('userDetails.table.userType.customer')
          }
        />
        <DataRow
          field={t('userDetails.table.phoneNumber.label')}
          data={
            <>
              {phoneNumber ? `${phoneNumberCountryCode} ${phoneNumber}` : '-'}
              <Badge
                className='ml-2'
                color={isPhoneNumberVerified ? 'success' : 'error'}
              >
                {isPhoneNumberVerified
                  ? t('userDetails.table.phoneNumber.status.verified')
                  : t('userDetails.table.phoneNumber.status.notVerified')}
              </Badge>
            </>
          }
        >
          {getPhoneNumberAction(
            isPhoneNumberVerified,
            lastIdVerificationStatus,
            guid
          )}
        </DataRow>
        <DataRow
          field={t('userDetails.table.mfa.label')}
          data={
            hasMfa
              ? t('userDetails.table.mfa.status.enabled')
              : t('userDetails.table.mfa.status.disabled')
          }
          fieldTooltipMessage={
            isMandatoryMfaEnabled
              ? t('userDetails.table.mfa.tooltip')
              : undefined
          }
        >
          {hasMfa &&
            (isMandatoryMfaEnabled ? (
              <ResetMfa userGuid={guid} />
            ) : (
              <DisableMfa userGuid={guid} />
            ))}
        </DataRow>
        {isMandatoryMfaEnabled && (
          <DataRow
            field={t('userDetails.table.mandatoryMfaReasons.label')}
            data={mandatoryMfaReasons.map((reason) => (
              <Badge key={reason} className='mr-1'>
                {reason}
              </Badge>
            ))}
          />
        )}
        <DataRow
          field={t('userDetails.table.identityVerification.label')}
          data={
            <span className='whitespace-nowrap'>
              {lastIdVerificationDate && (
                <span className='mr-2'>
                  {format(
                    new Date(lastIdVerificationDate),
                    Formats.dateTime.medium
                  )}
                </span>
              )}

              <IdVerificationBadge
                verificationStatus={lastIdVerificationStatus}
              />
            </span>
          }
        >
          {lastIdVerificationStatus && <UnverifyIdentity userGuid={guid} />}
          {lastIdVerificationInquiryID && (
            <ViewInquiryButton inquiryID={lastIdVerificationInquiryID} />
          )}
        </DataRow>
      </Body>
    </Table>
  )
}

const getAccountStatus = (
  deleted: boolean,
  deletedDate: number,
  hasBackendAccess: boolean,
  t: (key: string) => string
) => {
  const T_PATH = 'userDetails.table.accountStatus.status'
  if (deleted) {
    return `${t(`${T_PATH}.deleted`)} (${format(
      new Date(deletedDate),
      Formats.date.long
    )})`
  } else if (hasBackendAccess) {
    return t(`${T_PATH}.activated`)
  } else {
    return t(`${T_PATH}.notActivated`)
  }
}

const ViewInquiryButton = ({ inquiryID }: { inquiryID: string }) => {
  const { t } = useTranslation()
  return (
    <Button
      className='px-0'
      iconLeft={<LaunchIcon accessibility='decorative' />}
      variant='link'
      onClick={() =>
        window.open(
          `https://app.withpersona.com/dashboard/inquiries/${inquiryID}`,
          '_blank'
        )
      }
    >
      {t('userDetails.table.identityVerification.viewStatus')}
    </Button>
  )
}
const IdVerificationBadge = ({
  verificationStatus
}: {
  verificationStatus?: ID_VERIFICATION_STATUS | null
}) => {
  let badgeColor, badgeText
  const T_PATH = 'userDetails.table.identityVerification.status'
  const { t } = useTranslation()

  switch (verificationStatus) {
    case ID_VERIFICATION_STATUS.SUCCESS:
      badgeText = t(`${T_PATH}.verified`)
      badgeColor = Badge.Color.success
      break
    case ID_VERIFICATION_STATUS.FAILED:
      badgeText = t(`${T_PATH}.pendingVerification`)
      badgeColor = Badge.Color.warning
      break
    case ID_VERIFICATION_STATUS.FAILED_PERMANENTLY:
      badgeText = t(`${T_PATH}.verificationFailed`)
      badgeColor = Badge.Color.error
      break
    case ID_VERIFICATION_STATUS.REVERIFICATION_REQUESTED:
      badgeText = t(`${T_PATH}.reverificationRequested`)
      badgeColor = Badge.Color.info
      break
    default:
      badgeText = t(`${T_PATH}.notVerified`)

      badgeColor = Badge.Color.info
  }

  return <Badge color={badgeColor}>{badgeText}</Badge>
}

const getPhoneNumberAction = (
  isPhoneNumberVerified: boolean | undefined,
  lastIdVerificationStatus: ID_VERIFICATION_STATUS | null | undefined,
  guid: string
) => {
  if (
    isPhoneNumberVerified &&
    lastIdVerificationStatus === ID_VERIFICATION_STATUS.SUCCESS
  ) {
    return <UnverifyPhoneAndIdentity userGuid={guid} />
  }
  if (isPhoneNumberVerified) {
    return <UnverifyPhone userGuid={guid} />
  }
}
