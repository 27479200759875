import { useTranslation } from '@local/translations'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { SearchIcon, LookupInfoIcon } from '@toasttab/buffet-pui-icons'

export function NoUserFound() {
  const { t } = useTranslation()
  return (
    <EmptyState
      icon={<LookupInfoIcon accessibility='decorative' />}
      title={t('emptyState.notFound.title')}
    >
      {t('emptyState.notFound.message')}
    </EmptyState>
  )
}

export function NoUserSelected() {
  const { t } = useTranslation()
  return (
    <EmptyState
      icon={<SearchIcon accessibility='decorative' />}
      title={t('emptyState.notSelected.title')}
    >
      {t('emptyState.notSelected.message')}
    </EmptyState>
  )
}
