import { useTranslation } from '@local/translations'
import { UNVERIFY_PHONE_KEY, unverifyPhone } from '../../api/phoneVerification'
import { UserViewAction } from './UserViewAction'
import { CloseCircleIcon } from '@toasttab/buffet-pui-icons'

interface UnverifyPhoneProps {
  userGuid: string
}

export function UnverifyPhone({ userGuid }: UnverifyPhoneProps) {
  const { t } = useTranslation()

  const UnverifyPhoneModalBody = () => (
    <p>{t('actions.unverifyPhone.content')}</p>
  )

  return (
    <UserViewAction
      actionLabel={t('actions.unverifyPhone.title')}
      iconElement={<CloseCircleIcon accessibility='decorative' />}
      modalHeader={t('actions.unverifyPhone.message')}
      modalBodyContent={<UnverifyPhoneModalBody />}
      errorMessage={t('actions.unverifyPhone.error')}
      successMessage={t('actions.unverifyPhone.success')}
      userGuid={userGuid}
      mutationKey={UNVERIFY_PHONE_KEY}
      mutationFn={unverifyPhone}
    />
  )
}
