import * as React from 'react'
import { Formik, Form } from 'formik'
import {
  TextInputField,
  SelectField,
  SubmitButton,
  PhoneInputField
} from '@toasttab/buffet-pui-forms'
import { PhoneNumber } from '@toasttab/buffet-pui-phone-input'
import { defaultStrings as defaultCountryNameStrings } from '@toasttab/buffet-pui-country-utilities'
import { useTranslation } from '@local/translations'
import { Criterion } from '../../api/user'
import { CRITERIA, FORM_SCHEMA } from './schema'

interface SearchUserFormProps {
  isLoading?: boolean
  search?: string
  phone?: PhoneNumber
  criterion?: Criterion
  onSubmit(values: SearchUserFormValues): void
}

export interface SearchUserFormValues {
  search: string
  phone: PhoneNumber
  criterion: Criterion
}

const INPUT_TYPE_BY_CRITERION: {
  [key in Criterion]: React.HTMLInputTypeAttribute
} = {
  email: 'email',
  guid: 'text',
  phone: 'text'
}

export function SearchUserForm({
  isLoading,
  onSubmit,
  search = '',
  phone = { countryCode: '', nationalNumber: '' },
  criterion = 'email'
}: SearchUserFormProps) {
  const { t } = useTranslation()

  return (
    <Formik<SearchUserFormValues>
      initialValues={{ search, phone, criterion }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
      initialTouched={{ search: !!search }}
      validationSchema={FORM_SCHEMA}
      validateOnMount={true}
      enableReinitialize
    >
      {({ isValidating, isSubmitting, isValid, dirty, values }) => {
        return (
          <Form
            className='flex flex-col flex-wrap gap-4 md:flex-row md:gap-0'
            style={{ marginBottom: '20px' }}
          >
            <SelectField
              name='criterion'
              label={t('form.category.label')}
              options={CRITERIA}
              disabled={isLoading}
              containerClassName='md:w-4/12 lg:w-3/12'
            />
            {values.criterion === 'phone' ? (
              <PhoneInputField
                name='phone'
                label={t('form.inputField.label')}
                disabled={isLoading}
                countryNameStrings={defaultCountryNameStrings}
                strings={PhoneInputField.defaultStrings}
                containerClassName='md:w-6/12 md:px-3 lg:w-7/12'
              />
            ) : (
              <TextInputField
                name='search'
                label={t('form.inputField.label')}
                disabled={isLoading}
                type={INPUT_TYPE_BY_CRITERION[values.criterion]}
                containerClassName='md:w-6/12 md:px-3 lg:w-7/12'
              />
            )}
            <SubmitButton
              className='md:mt-7 md:w-2/12'
              disabled={
                isLoading || !dirty || !isValid || isValidating || isSubmitting
              }
              isInProgress={isSubmitting || isLoading}
            >
              {t('form.button')}
            </SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
