import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { AutorenewIcon, CloseCircleIcon } from '@toasttab/buffet-pui-icons'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { SEARCH_USER_KEY } from '../../api/user'
import { unverifyIdentity } from '../../api/identityVerification'
import { unverifyPhone } from '../../api/phoneVerification'
import { useTranslation } from '@local/translations'

export function UnverifyPhoneAndIdentity({ userGuid }: { userGuid: string }) {
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const screenSize = useScreenSize()

  const queryClient = useQueryClient()

  const {
    isLoading: isPhoneUnverifyLoading,
    mutateAsync: phoneUnverifyMutation
  } = useMutation((guid: string) => unverifyPhone(guid))

  const {
    isLoading: isIdentityUnverifyLoading,
    mutateAsync: identityUnverifyMutation
  } = useMutation((guid: string) => unverifyIdentity(guid))

  const handleUnverification = async (guid: string) => {
    try {
      await Promise.all([
        identityUnverifyMutation(guid),
        phoneUnverifyMutation(guid)
      ])
      queryClient.invalidateQueries(SEARCH_USER_KEY)
      showSuccessSnackBar(t('actions.unverifyPhoneAndIdentity.success'))
      setIsModalOpen(false)
    } catch (_) {
      showErrorSnackBar(t('actions.unverifyPhoneAndIdentity.error'))
    }
  }

  return (
    <>
      <Button
        className='px-0'
        iconLeft={<CloseCircleIcon accessibility='decorative' />}
        variant={Button.Variant?.link}
        disabled={isModalOpen}
        onClick={() => setIsModalOpen(true)}
        size={Button.Size?.sm}
      >
        {t('actions.unverifyPhoneAndIdentity.title')}
      </Button>
      <Modal
        isOpen={isModalOpen}
        size={Modal.Size.xxl}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>
          {t('actions.unverifyPhoneAndIdentity.message')}
        </ModalHeader>
        <ModalBody>
          <p>
            {t('actions.unverifyPhoneAndIdentity.content.usersCanReverify')}
            <br />
            <br />
            {t('actions.unverifyPhoneAndIdentity.content.idWillAlsoUnverify')}
          </p>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup
            className='w-full md:justify-end'
            direction={
              screenSize < ScreenSize.MD
                ? ButtonGroup.direction.vertical
                : ButtonGroup.direction.horizontal
            }
          >
            <Button
              variant={Button.Variant?.link}
              onClick={() => setIsModalOpen(false)}
              disabled={isIdentityUnverifyLoading || isPhoneUnverifyLoading}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              variant={Button.Variant?.destructive}
              disabled={isIdentityUnverifyLoading || isPhoneUnverifyLoading}
              iconLeft={
                isIdentityUnverifyLoading ||
                (isPhoneUnverifyLoading && (
                  <AutorenewIcon
                    accessibility='decorative'
                    className='animate-spin'
                  />
                ))
              }
              onClick={() => handleUnverification(userGuid)}
            >
              {t('actions.unverifyPhoneAndIdentity.title')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}
