import { Cell, Row, Table, Body } from '@toasttab/buffet-pui-table'
import {
  InvitationStatus,
  RestaurantInfoData
} from '../types/RestaurantInfoData'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button } from '@toasttab/buffet-pui-buttons'
import { RestartIcon } from '@toasttab/buffet-pui-icons'
import { useReinviteUser } from '../hooks/useReinviteUser'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

export const RestaurantStatusTable = ({
  invitationStatus,
  restaurantData,
  userGuid
}: {
  invitationStatus: InvitationStatus
  restaurantData: RestaurantInfoData
  userGuid: string
}) => {
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const { isLoading: isResendingInvitation, mutate: resendInvitation } =
    useReinviteUser(
      {
        restaurantGuid: restaurantData?.guid,
        userGuid
      },
      {
        onSuccess() {
          showSuccessSnackBar('Invitation email resent')
        },
        onError() {
          showErrorSnackBar('Failed to resend invitation email')
        }
      }
    )

  const { color, translation } = InvitationStatusMap[invitationStatus]
  return (
    <div className='flex flex-col w-full'>
      <Table>
        <Body>
          <Row>
            <Cell className='content-center'>Restaurant name</Cell>
            <Cell>{restaurantData?.name}</Cell>
          </Row>
          <Row>
            <Cell className='content-center'>Restaurant GUID</Cell>
            <Cell>{restaurantData?.guid}</Cell>
          </Row>
          <Row>
            <Cell className='content-center'>Invitation status</Cell>
            <Cell className='flex md:flex-row md:justify-between items-center'>
              <div>
                <Badge className='flex' color={color}>
                  {translation}
                </Badge>
              </div>
              <Button
                className='hidden md:flex'
                iconLeft={<RestartIcon accessibility='decorative' />}
                onClick={() => resendInvitation()}
                variant='link'
                disabled={
                  !canResendInvitation(invitationStatus) ||
                  isResendingInvitation
                }
                size={Button.Size?.sm}
              >
                Resend invitation
              </Button>
            </Cell>
          </Row>
          <Row>
            <Cell className='content-center'>Leaf GUID</Cell>
            <Cell>{restaurantData?.leafGuid}</Cell>
          </Row>
          <Row>
            <Cell className='content-center'>Management group name</Cell>
            <Cell>{restaurantData?.managementGroup?.name}</Cell>
          </Row>
          <Row>
            <Cell className='content-center'>Management group GUID</Cell>
            <Cell>{restaurantData?.managementGroup?.guid}</Cell>
          </Row>
        </Body>
      </Table>
      <Button
        className='mt-6 md:hidden'
        iconLeft={<RestartIcon accessibility='decorative' />}
        onClick={() => resendInvitation()}
        variant='link'
        disabled={
          !canResendInvitation(invitationStatus) || isResendingInvitation
        }
        size={Button.Size?.sm}
      >
        Resend invitation
      </Button>
    </div>
  )
}

export const InvitationStatusMap = {
  [InvitationStatus.ALREADY_ACTIVE]: {
    color: Badge.Color.success,
    translation: 'accepted'
  },
  [InvitationStatus.VALID]: {
    color: Badge.Color.info,
    translation: 'pending'
  },
  [InvitationStatus.RETRYABLE]: {
    color: Badge.Color.neutral3,
    translation: 'retryable'
  },
  [InvitationStatus.EXPIRED]: {
    color: Badge.Color.error,
    translation: 'expired'
  },
  [InvitationStatus.USER_NOT_FOUND_AT_RESTAURANT]: {
    color: Badge.Color.error,
    translation: 'User not found at restaurant'
  }
}

const canResendInvitation = (invitationStatus: InvitationStatus) =>
  ![
    InvitationStatus.USER_NOT_FOUND_AT_RESTAURANT,
    InvitationStatus.ALREADY_ACTIVE
  ].includes(invitationStatus)
