import { useQuery } from 'react-query'
import {
  GET_INVITATION_STATUS,
  getInvitationStatus
} from '../api/getInvitationStatus'
import { InvitationStatus } from '../types/RestaurantInfoData'

export function useGetInvitationStatus(
  variables: { userGuid: string; restaurantGuid: string },
  options?: {
    onError?: (err: unknown) => void
    onSuccess?: (data: InvitationStatus) => void
  }
) {
  const { data, isError, isLoading, refetch, status } = useQuery<
    InvitationStatus,
    Error
  >([GET_INVITATION_STATUS, variables], () => getInvitationStatus(variables), {
    ...options,
    enabled: false
  })

  return {
    data,
    fetch: refetch,
    isError,
    isLoading,
    status
  }
}
