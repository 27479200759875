import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { flexRender, Table as RTable } from '@tanstack/react-table'
import { PermissionsEnum } from '../../api/permission'

export const PermissionsTable = ({
  table
}: {
  table: RTable<PermissionsEnum>
}) => {
  const { getHeaderGroups, getRowModel } = table

  return (
    <Table>
      <Head>
        {getHeaderGroups().map((headerGroup) => (
          <Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <HeadingCell
                  className={'text-nowrap'}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </HeadingCell>
              )
            })}
          </Row>
        ))}
      </Head>
      <Body>
        {getRowModel().rows.map((row) => {
          return (
            <Row key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <Cell key={cell.id} className='text-nowrap'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}
