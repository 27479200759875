import { useCallback, ChangeEvent } from 'react'
import { CheckboxGroup } from '@toasttab/buffet-pui-checkbox'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'
import { PermissionsInit } from '../../api/permission'
import { useTranslation } from '@local/translations'

/**
 * Updating roles resets overrides, but this should only
 * reset for permissions not covered by existing roles
 *
 * O = Override is on (1)
 * ER = permission is on (1) in Existing Roles, excluding updated role
 * UR = permission is on (1) for Updated Role
 *
 * O ER UR | Overrides to Keep = O ^ (ER v ~R)
 * 1  1  1 | 1
 * 1  1  0 | 1
 * 1  0  1 | 0
 * 1  0  0 | 1
 * 0  1  1 | 0
 * 0  1  0 | 0
 * 0  0  1 | 0
 * 0  0  0 | 0
 */
export function userOverridesAfterRoleChange(
  allRolesAndPermissions: PermissionsInit,
  userRoles: string[],
  modifiedRole: string,
  userOverrides: bigint
): bigint {
  let existingRolePermissions = BigInt(0)

  allRolesAndPermissions.roles
    .filter(({ name }) => userRoles.includes(name) && name !== modifiedRole)
    .map(({ permissions }) => BigInt('0b' + permissions))
    .forEach((r) => (existingRolePermissions = existingRolePermissions | r))

  const rolePermissions = BigInt(
    '0b' +
      allRolesAndPermissions.roles.filter(
        ({ name }) => name === modifiedRole
      )[0].permissions
  )
  const overridesToKeep = existingRolePermissions | ~rolePermissions
  return userOverrides & overridesToKeep
}

export function isRoleDisabled(
  { universalAccess, administratorManagement }: EditorAccess,
  userRole: string
) {
  return !(
    universalAccess ||
    (administratorManagement && userRole !== TOAST_ADMIN_ROLE)
  )
}

export interface RoleGroupProps {
  userRoles: string[]
  setUserRoles: (userRoles: string[]) => void
  userOverrides: bigint
  setUserOverrides: (userOverrides: bigint) => void
  allRolesAndPermissions: PermissionsInit
  editorAccess: EditorAccess
}

export function RoleGroup({
  userRoles,
  setUserRoles,
  userOverrides,
  setUserOverrides,
  allRolesAndPermissions,
  editorAccess
}: RoleGroupProps) {
  const { t } = useTranslation()
  const onRoleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value
      const set = new Set(userRoles)
      let addingAdminRole = false
      if (set.has(value)) {
        set.delete(value)
      } else {
        set.add(value)
        if (value === TOAST_ADMIN_ROLE) {
          addingAdminRole = true
        }
      }

      if (addingAdminRole) {
        setUserOverrides(BigInt(0))
      } else {
        setUserOverrides(
          userOverridesAfterRoleChange(
            allRolesAndPermissions,
            userRoles,
            value,
            userOverrides
          )
        )
      }
      const rolesArray = Array.from(set)
      setUserRoles(rolesArray)
    },
    [
      userRoles,
      userOverrides,
      allRolesAndPermissions,
      setUserOverrides,
      setUserRoles
    ]
  )

  const userRolesCheckBoxes = allRolesAndPermissions.roles.map(
    ({ name, title }) => ({
      checked: userRoles.includes(name),
      label: title,
      value: name,
      key: name,
      disabled: isRoleDisabled(editorAccess, name)
    })
  )

  return (
    <CheckboxGroup
      className='pl-3'
      label={t('adminAccess.roleGroup.label')}
      onChange={onRoleChange}
      options={userRolesCheckBoxes}
    />
  )
}
