import { QueryStatus, useQuery } from 'react-query'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LookupInfoIcon, WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useTranslation } from '@local/translations'
import {
  GET_RESTAURANT_ACCESS_KEY,
  getRestaurantAccess,
  RestaurantAccessResponse
} from '../../api/restaurantAccess'
import { RestaurantAccessSuccessView } from './RestaurantAccessSuccessView'

export function RestaurantAccess({ userGuid }: { userGuid: string }) {
  const {
    status,
    data,
    refetch: refreshRestaurantAccess
  } = useQuery(GET_RESTAURANT_ACCESS_KEY, () => getRestaurantAccess(userGuid))

  return (
    <div>
      {
        {
          ['error' as QueryStatus]: <ErrorState />,
          ['loading' as QueryStatus]: <LoadingState />,
          ['success' as QueryStatus]: (
            <SuccessState
              userGuid={userGuid}
              restaurantAccessList={data as RestaurantAccessResponse[]}
              refreshRestaurantAccess={refreshRestaurantAccess}
            />
          )
        }[status]
      }
    </div>
  )
}

const LoadingState = () => (
  <div className='md:min-h-64  mt-10 flex items-center justify-center w-full'>
    <MerryGoRound testId='loading-animation' size='lg' />
  </div>
)

const ErrorState = () => {
  const { t } = useTranslation()
  return (
    <div className='md:min-h-64 mt-10 flex items-center w-full'>
      <EmptyState
        icon={<WarningOutlineIcon accessibility='decorative' />}
        title={t('restaurantAccess.errorState.title')}
      >
        {t('restaurantAccess.errorState.message')}
      </EmptyState>
    </div>
  )
}
const SuccessState = ({
  userGuid,
  restaurantAccessList,
  refreshRestaurantAccess
}: {
  userGuid: string
  restaurantAccessList: RestaurantAccessResponse[]
  refreshRestaurantAccess: () => void
}) => {
  const { t } = useTranslation()
  if (restaurantAccessList.length === 0) {
    return (
      <div className='md:min-h-64  mt-10 flex items-center w-full'>
        <EmptyState
          icon={<LookupInfoIcon accessibility='decorative' />}
          title={t('restaurantAccess.emptyState.title')}
        >
          {t('restaurantAccess.emptyState.message')}
        </EmptyState>
      </div>
    )
  } else {
    return (
      <RestaurantAccessSuccessView
        userGuid={userGuid}
        restaurantAccessList={restaurantAccessList}
        refreshRestaurantAccess={refreshRestaurantAccess}
      />
    )
  }
}
