import { useMutation, useQueryClient } from 'react-query'
import { SplitButton, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { ApiError } from '../../types/ApiError'
import { cleanPermissions } from '../../utils/permissionUtil'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'
import { PATCH_ADMIN_ACCESS, patchAdminAccess } from '../../api/administrator'
import { useTranslation } from '@local/translations'
import { RestartIcon } from '@toasttab/buffet-pui-icons'
import { recomputeAdminAccess } from '../../api/recomputeAdminAccess'

export interface SaveAccessButtonProps {
  editorAccess: EditorAccess
  largestPermissionBit: number
  userGuid: string
  userInheritedPermissions: bigint
  userOverrides: bigint
  userRoles: string[]
}

export function SaveAccessButton({
  editorAccess,
  largestPermissionBit,
  userGuid,
  userInheritedPermissions,
  userOverrides,
  userRoles
}: SaveAccessButtonProps) {
  const { t } = useTranslation()
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    PATCH_ADMIN_ACCESS,
    () =>
      patchAdminAccess(userGuid, {
        roles: userRoles,
        overrideFlags: cleanPermissions(userOverrides, largestPermissionBit),
        overrides: cleanPermissions(
          userInheritedPermissions ^ userOverrides,
          largestPermissionBit
        )
      }),
    {
      onError(error: ApiError) {
        showErrorSnackBar(error?.message || t('adminAccess.save.error'))
      },
      onSuccess() {
        queryClient.invalidateQueries()
        showSuccessSnackBar(t('adminAccess.save.success'))
      }
    }
  )

  const {
    isLoading: isRecomputeAccessLoading,
    mutateAsync: recomputeAccessMutation
  } = useMutation((guid: string) => recomputeAdminAccess(guid), {
    onError(error: ApiError) {
      showErrorSnackBar(
        error?.message || t('adminAccess.save.button.recomputeAccess.error')
      )
    },
    onSuccess() {
      queryClient.invalidateQueries()
      showSuccessSnackBar(t('adminAccess.save.button.recomputeAccess.success'))
    }
  })

  const isDisabled = (
    isLoading: boolean,
    isRecomputeAccessLoading: boolean,
    editorAccess: EditorAccess,
    userRoles: string[]
  ) => {
    return (
      isLoading ||
      isRecomputeAccessLoading ||
      !(
        editorAccess.universalAccess ||
        (editorAccess.administratorManagement &&
          !userRoles.includes(TOAST_ADMIN_ROLE))
      )
    )
  }

  return (
    <SplitButton
      text={t('adminAccess.save.button')}
      disabled={isDisabled(
        isLoading,
        isRecomputeAccessLoading,
        editorAccess,
        userRoles
      )}
      onClick={() => mutate()}
      dropdownToggleLabel={t('adminAccess.save.button.dropdown')}
      containerClassName={'pb-4'}
    >
      <ListItem
        onClick={() => recomputeAccessMutation(userGuid)}
        icon={<RestartIcon accessibility='decorative' />}
        label={t('adminAccess.save.button.recomputeAccess.label')}
      />
    </SplitButton>
  )
}
