import { useMutation } from 'react-query'
import { REINVITE_USER_KEY, reinviteUser } from '../api/reinviteUser'

export function useReinviteUser(
  variables: { userGuid: string; restaurantGuid: string },
  options?: {
    onError?: (err: unknown) => void
    onSuccess?: (data: unknown) => void
  }
) {
  const { data, isError, isLoading, status, mutate } = useMutation<
    unknown,
    Error
  >([REINVITE_USER_KEY, variables], () => reinviteUser(variables), {
    ...options
  })

  return {
    data,
    mutate,
    isError,
    isLoading,
    status
  }
}
