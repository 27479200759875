import { object, string } from 'yup'
import { validationSchema } from '@toasttab/buffet-pui-phone-input'
import { isValidEmail } from '@toasttab/email-validation'
import { Criterion } from '../../api/user'
import { isValidUuid } from '../../utils/isValidUuid'

export const CRITERIA: { value: Criterion; label: string }[] = [
  {
    value: 'email',
    label: 'Email address'
  },
  {
    value: 'guid',
    label: 'User identifier'
  },
  {
    value: 'phone',
    label: 'Verified phone number'
  }
]

export const criteriaValues = CRITERIA.map(({ value }) => value)

export const FORM_SCHEMA = object().shape({
  search: string()
    .when('criterion', {
      is: 'email',
      then: (schema) =>
        schema.test(
          'email',
          'Please enter a valid email address',
          (search) => !!search && isValidEmail(search)
        )
    })
    .when('criterion', {
      is: 'guid',
      then: (schema) =>
        schema.test(
          'guid',
          'Please enter a valid user GUID',
          (search) => !!search && isValidUuid(search)
        )
    }),
  phone: object()
    .shape({
      countryCode: string(),
      nationalNumber: string()
    })
    .when('criterion', {
      is: 'phone',
      then: () => validationSchema
    })
})
