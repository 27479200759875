import cx from 'classnames'
import { flexRender, HeaderGroup, RowModel } from '@tanstack/react-table'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Pagination, useRowPagination } from '@toasttab/buffet-pui-pagination'
import { RestaurantAccessResponse } from '../../api/restaurantAccess'

export function RestaurantAccessTable({
  getHeaderGroups,
  getFilteredRowModel
}: {
  getHeaderGroups: () => HeaderGroup<RestaurantAccessResponse>[]
  getFilteredRowModel: () => RowModel<RestaurantAccessResponse>
}) {
  const { currentPageData, ...paginationProps } = useRowPagination({
    rows: getFilteredRowModel().rows,
    pageSize: 15
  })
  return (
    <>
      <div className='overflow-x-auto'>
        <Table>
          <Head>
            {getHeaderGroups().map((headerGroup) => (
              <Row key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <HeadingCell
                      className={cx('text-nowrap', { 'w-7': index === 0 })}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </>
                      )}
                    </HeadingCell>
                  )
                })}
              </Row>
            ))}
          </Head>

          <Body>
            {currentPageData.map((row) => {
              return (
                <Row key={row.id}>
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <Cell
                        key={cell.id}
                        className={cx('text-nowrap', {
                          '!py-0 pr-0': index === 0
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Cell>
                    )
                  })}
                </Row>
              )
            })}
          </Body>
        </Table>
      </div>
      <Pagination
        {...paginationProps}
        className='flex flex-row justify-center md:justify-end pt-4'
      />
    </>
  )
}
