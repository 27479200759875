import { QueryStatus } from 'react-query'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@toasttab/buffet-pui-tabs'
import { useTranslation } from '@local/translations'
import { OptionalUser } from '../../api/user'
import { AdministratorAccess } from '../AdministratorAccess'
import { NoUserFound, NoUserSelected } from '../EmptyState'
import { LoadingState } from '../LoadingState'
import { ViewUser } from '../ViewUser'
import { RestaurantAccess } from '../RestaurantAccess'
import { RestaurantUserLookup } from '@local/restaurant-user-lookup'

export interface MainContentProps {
  status: QueryStatus
  user?: OptionalUser
}

export function MainContent({ status, user }: MainContentProps) {
  return (
    <div className='flex w-full items-center md:min-h-64'>
      {
        {
          ['idle' as QueryStatus]: <NoUserSelected />,
          ['error' as QueryStatus]: <NoUserSelected />,
          ['loading' as QueryStatus]: <LoadingState />,
          ['success' as QueryStatus]: <SuccessState user={user} />
        }[status]
      }
    </div>
  )
}

const SuccessState = ({ user }: { user?: OptionalUser }) => {
  const { t } = useTranslation()
  if (!user) {
    return <NoUserFound />
  } else {
    return (
      <Tabs className='w-full'>
        <TabList>
          <Tab>{t('tabs.labels.userDetails')}</Tab>
          <Tab>{t('tabs.labels.adminAccess')}</Tab>
          <Tab>{t('tabs.labels.restaurantAccess')}</Tab>
          <Tab>{t('tabs.labels.invitationStatus')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className='overflow-x-auto pt-4'>
            <ViewUser user={user} />
          </TabPanel>
          <TabPanel className='overflow-x-auto pt-4'>
            <AdministratorAccess
              userGuid={user.guid}
              access={user.adminAccess}
            />
          </TabPanel>
          <TabPanel className='overflow-x-auto pt-4'>
            <RestaurantAccess userGuid={user.guid} />
          </TabPanel>
          <TabPanel className='overflow-x-auto pt-4'>
            <RestaurantUserLookup userGuid={user.guid} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }
}
