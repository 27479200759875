import { useTranslation } from '@local/translations'
import { Head, HeadingCell, Row } from '@toasttab/buffet-pui-table'

export function Header() {
  const { t } = useTranslation()
  const T_PATH = 'userDetails.table.headings'
  return (
    <Head>
      <Row>
        <HeadingCell>{t(`${T_PATH}.field`)}</HeadingCell>
        <HeadingCell>{t(`${T_PATH}.content`)}</HeadingCell>
        <HeadingCell>{t(`${T_PATH}.action`)}</HeadingCell>
      </Row>
    </Head>
  )
}
