import { QueryClientProvider, QueryClient } from 'react-query'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { TranslationProvider } from '@local/translations'
import { App } from './App'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

export function AppSetup() {
  return (
    <TranslationProvider>
      <QueryClientProvider client={client}>
        <SnackBarProvider>
          <App />
        </SnackBarProvider>
      </QueryClientProvider>
    </TranslationProvider>
  )
}
