import { gql, request } from 'graphql-request'
import { GetRestaurantInfoData } from '../types/RestaurantInfoData'

export const GET_RESTAURANT_INFO_KEY = 'get_restaurant_info_key'

export const GET_RESTAURANT_INFO_QUERY = gql`
  query Restaurants(
    $searchTerm: String
    $isCustomer: Boolean = false
    $pageNumber: Int = 1
  ) {
    searchRestaurants(
      searchTerm: $searchTerm
      isCustomer: $isCustomer
      pageNumber: $pageNumber
    ) {
      name
      guid
      leafGuid
      address {
        city
        state
        zip
      }
      managementGroup {
        name
        guid
      }
    }
  }
`

export const getRestaurantInfo = async (variables: { searchTerm: string }) =>
  await request<Promise<GetRestaurantInfoData>>(
    '/api/service/bff-customer-access/v1/graphql',
    GET_RESTAURANT_INFO_QUERY,
    variables
  )
