export const BASE_ROUTES = {
  PERMISSIONS: '/api/service/permissions',
  USERS: '/api/service/users',
  IDENTITY_ATTRIBUTES: '/api/service/identity-attributes'
}
export const METHOD_DELETE = 'DELETE'
export const METHOD_GET = 'GET'
export const METHOD_PATCH = 'PATCH'
export const METHOD_POST = 'POST'

export const ROUTE_USERS_CUSTOMERS = BASE_ROUTES.USERS + '/v1/customers'
export const ROUTE_USERS_ADMIN =
  BASE_ROUTES.USERS + '/v1/administrators/administrative'
export const ROUTE_USERS_PHONE_VERIFICATION =
  BASE_ROUTES.USERS + '/v1/customers/phone/verify'
export const ROUTE_PERMISSIONS_ADMIN_INIT =
  BASE_ROUTES.PERMISSIONS + '/v2/permissions/administrative/audit/initialize'
export const ROUTE_IDENTITY_ATTRIBUTES_RESTAURANT_ACCESS =
  BASE_ROUTES.IDENTITY_ATTRIBUTES +
  '/v1/user-restaurant/list/restaurant-access/full/user'

export const PARTIAL_ROUTE_VERIFICATION_RESET = '/id/verification/reset'
export const PARTIAL_ROUTE_RECOMPUTE_ACCESS = '/recompute/access'
