import { useTranslation } from '@local/translations'
import { UserViewAction } from './UserViewAction'
import { RESET_MFA_KEY, resetMfa } from '../../api/mfa'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

interface ResetMfaProps {
  userGuid: string
}

const ResetMfaModalBody = () => {
  const { t } = useTranslation()
  return (
    <>
      <p>{t('actions.resetMfa.content')}</p>
    </>
  )
}

export function ResetMfa({ userGuid }: ResetMfaProps) {
  const { t } = useTranslation()

  return (
    <UserViewAction
      actionLabel={t('actions.resetMfa.title')}
      iconElement={<AutorenewIcon accessibility='decorative' />}
      modalHeader={t('actions.resetMfa.message')}
      modalBodyContent={<ResetMfaModalBody />}
      errorMessage={t('actions.resetMfa.error')}
      successMessage={t('actions.resetMfa.success')}
      userGuid={userGuid}
      mutationKey={RESET_MFA_KEY}
      mutationFn={resetMfa}
    />
  )
}
