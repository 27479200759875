import * as React from 'react'
import { useQuery } from 'react-query'
import { PhoneNumber } from '@toasttab/buffet-pui-phone-input'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Panel,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { useTranslation } from '@local/translations'
import { Criterion, SEARCH_USER_KEY, searchUser } from '../../api/user'
import { FORM_SCHEMA } from '../SearchUserForm/schema'
import { getInitialValuesFromParam } from '../../utils/params'
import { MainContent } from '../MainContent'
import { SearchUserForm } from '../SearchUserForm'

function isQueryEnabled(
  search: string,
  phone: PhoneNumber,
  criterion: Criterion,
  fieldsValid: boolean
) {
  if (!fieldsValid) {
    return false
  } else if (
    criterion === 'phone' &&
    !!phone.countryCode &&
    !!phone.nationalNumber
  ) {
    return true
  } else if (!!search) {
    return true
  } else {
    return false
  }
}

function useSearchUserForm() {
  const [values, setValues] = React.useState<{
    search: string
    phone: PhoneNumber
    criterion: Criterion
  }>(getInitialValuesFromParam())

  const { showErrorSnackBar } = useSnackBar()
  const fieldsValid = FORM_SCHEMA.isValidSync(values)
  const { search, phone, criterion } = values

  const userResults = useQuery(
    [SEARCH_USER_KEY, values],
    () =>
      searchUser(
        criterion === 'phone'
          ? { search: phone, criterion }
          : { search, criterion }
      ),
    {
      enabled: isQueryEnabled(search, phone, criterion, fieldsValid),
      onError(e) {
        showErrorSnackBar(String(e))
      }
    }
  )

  return {
    ...userResults,
    setQueryVariables: setValues,
    search,
    phone,
    criterion
  }
}

export function App() {
  const {
    data: user,
    status,
    setQueryVariables,
    search,
    phone,
    criterion
  } = useSearchUserForm()

  const { t } = useTranslation()
  return (
    <Page>
      <PageHeader>
        <HeadingGroup subTitle={t('subtitle')}>
          <Title>{t('title')}</Title>
        </HeadingGroup>
      </PageHeader>
      <PageBody>
        <Panel className='max-w-[100vw]'>
          <SearchUserForm
            isLoading={status === 'loading'}
            search={search}
            phone={phone}
            criterion={criterion}
            onSubmit={(values) => setQueryVariables(values)}
          />
          <MainContent status={status} user={user} />
        </Panel>
      </PageBody>
    </Page>
  )
}
