import { useMemo } from 'react'
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  Row
} from '@tanstack/react-table'
import { useTranslation } from '@local/translations'
import { PermissionsTable } from './PermissionsTable'
import { PermissionsEnum } from '../../api/permission'
import { TextWithOptionalTooltip } from './TextWithOptionalTooltip'
import { ReadonlyPermission } from './ReadonlyPermission'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'
import { permissionOn, togglePermissionBit } from '../../utils/permissionUtil'

export interface PermissionsTableWrapperInterface {
  allPermissions: PermissionsEnum[]
  userInheritedPermissions: bigint
  userRoles: string[]
  editorAccess: EditorAccess
  userOverrides: bigint
  setUserOverrides: (userOverrides: bigint) => void
}

export function PermissionsTableWrapper({
  allPermissions,
  userInheritedPermissions,
  userRoles,
  editorAccess: { universalAccess, administratorManagement },
  userOverrides,
  setUserOverrides
}: PermissionsTableWrapperInterface) {
  const { t } = useTranslation()

  const memoizedData = useMemo(() => allPermissions, [allPermissions])

  const columns: ColumnDef<PermissionsEnum, unknown>[] = [
    {
      header: () => <div className='flex justify-center'>#</div>,
      id: 'permissions-table-bit-position',
      cell: ({ row }: { row: Row<PermissionsEnum> }) => (
        <div className='flex justify-center'>{row.original.positionBit}</div>
      ),
      enableSorting: false
    },
    {
      header: t('adminAccess.permissionsTable.header.name'),
      id: 'permissions-table-name',
      cell: ({ row }: { row: Row<PermissionsEnum> }) => (
        <TextWithOptionalTooltip
          text={row.original.title}
          tooltip={row.original.description}
        />
      ),
      enableSorting: false
    },
    {
      header: () => (
        <div className='flex justify-center'>
          {t('adminAccess.permissionsTable.header.inherited')}
        </div>
      ),
      id: 'permissions-table-inherited',
      cell: ({ row }: { row: Row<PermissionsEnum> }) => (
        <ReadonlyPermission
          permissions={userInheritedPermissions}
          position={row.original.positionBit}
        />
      ),
      enableSorting: false
    },
    {
      header: () => (
        <div className='flex justify-center'>
          {t('adminAccess.permissionsTable.header.overridesInherited')}
        </div>
      ),
      id: 'permissions-table-overrides',
      cell: ({ row }: { row: Row<PermissionsEnum> }) => (
        <div className='flex justify-center'>
          <ToggleSwitch
            isActive={
              !userRoles.includes(TOAST_ADMIN_ROLE) &&
              permissionOn(userOverrides, row.original.positionBit)
            }
            disabled={
              userRoles.includes(TOAST_ADMIN_ROLE) ||
              !(universalAccess || administratorManagement)
            }
            onChange={() =>
              setUserOverrides(
                togglePermissionBit(userOverrides, row.original.positionBit)
              )
            }
            aria-label={row.original.title}
            testId={'permissions-table-overrides-' + row.original.positionBit}
          />
        </div>
      ),
      enableSorting: false
    },
    {
      header: t('adminAccess.permissionsTable.header.resolved'),
      id: 'permissions-table-resolved',
      cell: ({ row }: { row: Row<PermissionsEnum> }) => (
        <ReadonlyPermission
          permissions={userInheritedPermissions ^ userOverrides}
          position={row.original.positionBit}
        />
      ),
      enableSorting: false
    }
  ]

  const table = useReactTable({
    data: memoizedData,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className='overflow-x-auto'>
      <PermissionsTable table={table} />
    </div>
  )
}
