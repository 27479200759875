import { ApiError } from '../types/ApiError'
import { getAuthenticityToken } from '../utils/getAuthenticityToken'
import { ROUTE_IDENTITY_ATTRIBUTES_RESTAURANT_ACCESS } from './constants'

export interface RestaurantAccessResponse {
  guid: string
  restaurantSetGuid: string
  managementSetGuid?: string
  description?: string
  locationName?: string
  name: string
  restaurantUserGuid: string
}

export const GET_RESTAURANT_ACCESS_KEY = 'getRestaurantAccessKey'

export async function getRestaurantAccess(userGuid: string) {
  const response = await fetch(
    `${ROUTE_IDENTITY_ATTRIBUTES_RESTAURANT_ACCESS}/${userGuid}?includeInactiveRestaurants=true`
  )

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return (await response.json()) as RestaurantAccessResponse[]
}

export async function updateRestaurantAccess(
  userGuid: string,
  restaurantGuids: string[]
) {
  const formData = buildUpdateAccessFormData(userGuid, restaurantGuids)
  try {
    const response = await fetch('/toast/admin/render/users/updateuser', {
      method: 'POST',
      body: formData
    })
    if (!response.ok) {
      throw new Error('Failed to update restaurant access.')
    }
  } catch (error) {
    console.error('Error updating restaurant access:', error)
    throw error
  }
}

export const buildUpdateAccessFormData = (
  userGuid: string,
  restaurantGuids: string[]
) => {
  const formData = new FormData()

  formData.append('guid', userGuid)
  formData.append('authenticityToken', getAuthenticityToken())
  formData.append('updatePasscode', 'false')
  restaurantGuids.forEach((rGuid) => formData.append('rGuids', rGuid))
  return formData
}
