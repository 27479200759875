import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel as getFilteredRowModelRT,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import {
  RestaurantAccessResponse,
  updateRestaurantAccess
} from '../../api/restaurantAccess'
import { RestaurantAccessTable } from './RestaurantAccessTable'
import { RevokeRestaurantAccessModal } from './RevokeRestaurantAccessModal'

export interface RestaurantAccessSuccessViewProps {
  userGuid: string
  restaurantAccessList: RestaurantAccessResponse[]
  refreshRestaurantAccess: () => void
}
export function RestaurantAccessSuccessView({
  userGuid,
  restaurantAccessList,
  refreshRestaurantAccess
}: RestaurantAccessSuccessViewProps) {
  const [rowSelection, setRowSelection] = useState({})
  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] = useState(false)
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { t } = useTranslation()
  const memoizedData = useMemo(
    () => restaurantAccessList,
    [restaurantAccessList]
  )

  const columns: ColumnDef<RestaurantAccessResponse, unknown>[] = [
    {
      id: 'select',
      cell: ({ row }) => (
        <Checkbox
          containerClassName='p-0'
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
          label=''
        />
      )
    },
    {
      header: t('restaurantAccess.table.headings.name'),
      enableSorting: false,
      accessorKey: 'name'
    },
    {
      header: t('restaurantAccess.table.headings.locationName'),
      enableSorting: false,
      accessorKey: 'locationName'
    },
    {
      header: t('restaurantAccess.table.headings.restaurantUserGuid'),
      enableSorting: false,
      accessorKey: 'restaurantUserGuid'
    },
    {
      header: t('restaurantAccess.table.headings.restaurantSetGuid'),
      enableSorting: false,
      accessorKey: 'restaurantSetGuid'
    },
    {
      header: t('restaurantAccess.table.headings.managementSetGuid'),
      enableSorting: false,
      accessorKey: 'managementSetGuid'
    }
  ]

  const table = useReactTable({
    data: memoizedData,
    columns,
    state: { rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModelRT(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const { mutate: revokeAccess, isLoading } = useMutation(
    () => {
      const selectedRestaurantsGuids = getSelectedRowModel().rows.map(
        ({ original: { guid } }) => guid
      )
      const updatedRestaurantGuids = restaurantAccessList
        .filter(({ guid }) => !selectedRestaurantsGuids.includes(guid))
        .map(({ guid }) => guid)

      return updateRestaurantAccess(userGuid, updatedRestaurantGuids)
    },
    {
      onSuccess: async () => {
        showSuccessSnackBar('Restaurant access successfully revoked')
        await refreshRestaurantAccess()
        setRowSelection({})
        setIsRevokeAccessModalOpen(false)
      },
      onError(error: unknown) {
        console.warn(error)
        showErrorSnackBar('An error occurred while revoking access')
      }
    }
  )

  const { getHeaderGroups, getSelectedRowModel, getFilteredRowModel } = table

  const { getFilterValue, setFilterValue } =
    table?.getHeaderGroups()?.[0]?.headers?.[1]?.column

  return (
    <div className='overflow-x-auto'>
      <div className='flex items-end md:flex-row flex-col mb-10 px-1'>
        <TextInput
          containerClassName='w-full md:w-1/2'
          label={t('restaurantAccess.filter.label')}
          value={getFilterValue() as string}
          onChange={(e) => setFilterValue(e.target.value)}
        />
        <div className='grow px-4 py-2'>
          {`${Object.keys(rowSelection).length} of ${
            table.getPreFilteredRowModel().rows.length
          } restaurants selected`}
        </div>

        <Button
          className='w-full md:w-2/12 mb-6 md:mb-0'
          variant={Button.Variant?.destructive}
          onClick={() => setIsRevokeAccessModalOpen(true)}
          disabled={!Object.keys(rowSelection).length}
        >
          {t('restaurantAccess.revokeAccess.button.label')}
        </Button>
      </div>
      <RestaurantAccessTable
        getHeaderGroups={getHeaderGroups}
        getFilteredRowModel={getFilteredRowModel}
      />

      <RevokeRestaurantAccessModal
        selectedRestaurants={getSelectedRowModel().rows.map(
          (row) => row.original.name
        )}
        isOpen={isRevokeAccessModalOpen}
        isLoading={isLoading}
        onConfirm={revokeAccess}
        onCancel={() => setIsRevokeAccessModalOpen(false)}
      />
    </div>
  )
}
