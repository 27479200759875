import { useTranslation } from '@local/translations'
import {
  UNVERIFY_IDENTITY_KEY,
  unverifyIdentity
} from '../../api/identityVerification'
import { UserViewAction } from './UserViewAction'
import { CloseCircleIcon } from '@toasttab/buffet-pui-icons'

interface UnverifyIdentityProps {
  userGuid: string
}

export function UnverifyIdentity({ userGuid }: UnverifyIdentityProps) {
  const { t } = useTranslation()

  const UnverifyIdentityModalBody = () => (
    <p>{t('actions.unverifyIdentity.content')}</p>
  )

  return (
    <UserViewAction
      actionLabel={t('actions.unverifyIdentity.title')}
      iconElement={<CloseCircleIcon accessibility='decorative' />}
      modalHeader={t('actions.unverifyIdentity.message')}
      modalBodyContent={<UnverifyIdentityModalBody />}
      errorMessage={t('actions.unverifyIdentity.error')}
      successMessage={t('actions.unverifyIdentity.success')}
      userGuid={userGuid}
      mutationKey={UNVERIFY_IDENTITY_KEY}
      mutationFn={unverifyIdentity}
    />
  )
}
