import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { AdminAccess } from '../../api/administrator'
import { getEditorAccess } from '../../utils/editorAccess'
import { largestPermissionBit } from '../../utils/permissionUtil'
import { PERMISSIONS_INIT_KEY, permissionsInit } from '../../api/permission'
import { PermissionsTableWrapper } from './PermissionsTableWrapper'
import { RoleGroup } from './RoleGroup'
import { SaveAccessButton } from './SaveAccessButton'
import { useTranslation } from '@local/translations'

function useRolesAndPermissions() {
  const { showErrorSnackBar } = useSnackBar()

  return useQuery(PERMISSIONS_INIT_KEY, permissionsInit, {
    onError(e) {
      showErrorSnackBar(String(e))
    }
  })
}

export interface AdministratorAccessProps {
  userGuid: string
  access: AdminAccess
}

export function AdministratorAccess({
  userGuid,
  access
}: AdministratorAccessProps) {
  const { t } = useTranslation()
  const { auth } = useBanquetProps()
  const { data: allRolesAndPermissions } = useRolesAndPermissions()

  const [userRoles, setUserRoles] = useState(access.roles)
  const [userOverrides, setUserOverrides] = useState(
    BigInt(access.overrideFlags)
  )
  const [userInheritedPermissions, setUserInheritedPermissions] = useState(
    BigInt(0)
  )

  useEffect(() => {
    let updatedInheritedPermissions = BigInt(0)
    allRolesAndPermissions?.roles
      .filter((r) => userRoles.includes(r.name))
      .map(({ permissions }) => BigInt('0b' + permissions))
      .forEach(
        (r) => (updatedInheritedPermissions = updatedInheritedPermissions | r)
      )
    setUserInheritedPermissions(updatedInheritedPermissions)
  }, [userRoles, allRolesAndPermissions])

  useEffect(() => {
    setUserRoles(access.roles)
    setUserOverrides(BigInt(access.overrideFlags))
  }, [access])

  const editorRoles = auth?.userInfo.roles
  const editorPermissions = auth?.userInfo.resolvedToastPermissions

  if (!!allRolesAndPermissions && !!editorRoles && !!editorPermissions) {
    const editorAccess = getEditorAccess(editorRoles, editorPermissions)
    const largestBit = largestPermissionBit(
      allRolesAndPermissions.administrativePermissions.map((p) => p.positionBit)
    )

    return (
      <>
        <div className='flex flex-row justify-between'>
          <RoleGroup
            userRoles={userRoles}
            setUserRoles={setUserRoles}
            userOverrides={userOverrides}
            setUserOverrides={setUserOverrides}
            allRolesAndPermissions={allRolesAndPermissions}
            editorAccess={editorAccess}
          />
          <SaveAccessButton
            editorAccess={editorAccess}
            largestPermissionBit={largestBit}
            userGuid={userGuid}
            userInheritedPermissions={userInheritedPermissions}
            userOverrides={userOverrides}
            userRoles={userRoles}
          />
        </div>
        <Alert className='my-4' testId='role-info-alert'>
          <p className='mb-2'>
            {t('adminAccess.warning.changingWillAffectInherits')}
          </p>
          <p>{t('adminAccess.warning.onlyAffectedChangedRoles')}</p>
        </Alert>
        <div className='type-default text-default font-semibold'>
          {t('adminAccess.permission.subheading')}
        </div>
        <PermissionsTableWrapper
          userInheritedPermissions={userInheritedPermissions}
          userOverrides={userOverrides}
          allPermissions={allRolesAndPermissions.administrativePermissions.sort(
            (a, b) => a.positionBit - b.positionBit
          )}
          editorAccess={editorAccess}
          userRoles={userRoles}
          setUserOverrides={setUserOverrides}
        />
      </>
    )
  } else {
    return (
      <Alert variant='error' testId='roles-and-permissions-error-alert'>
        {t('adminAccess.error')}
      </Alert>
    )
  }
}
