import { ApiError } from '../types/ApiError'

export const REINVITE_USER_KEY = 'REINVITE_USER_KEY'

export const reinviteUser = async ({
  userGuid,
  restaurantGuid
}: {
  userGuid: string
  restaurantGuid: string
}) => {
  const response = await fetch(
    `/toast/admin/${userGuid}/reinvite?restaurantGuid=${restaurantGuid}`,
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({})
    }
  )

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
