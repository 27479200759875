export type GetRestaurantInfoData = {
  searchRestaurants: RestaurantInfoData[]
}

export type RestaurantInfoData = {
  name: string
  guid: string
  leafGuid: string
  address: {
    city: string
    state: string
    zip: string
  }
  managementGroup: {
    name: string
    guid: string
  }
}

// Based on the responses from Identity Attributes,
// https://github.toasttab.com/toasttab/toast-identity-attributes/blob/e2a5535dd1f79856ce00f053854cc9e407b766be/toast-identity-attributes-api/src/main/kotlin/com/toasttab/service/identityattributes/api/enums/UserRestaurantInvitationStatusEnum.kt
export enum InvitationStatus {
  VALID = 'VALID', // Invitation exists and is not expired
  RETRYABLE = 'RETRYABLE', // Invitation exist but is not active anymore
  EXPIRED = 'EXPIRED', // Invitation exist but is expired - user can't be activated with this key
  ALREADY_ACTIVE = 'ALREADY_ACTIVE',
  USER_NOT_FOUND_AT_RESTAURANT = 'USER_NOT_FOUND_AT_RESTAURANT'
}
