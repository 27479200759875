import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { AppSetup } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-adu',
  rootComponent: AppSetup,
  sentry: {
    publicKey: 'd32385fa9dfe4130a162de7bde802541@o37442',
    projectId: '6655213',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-admin-debug-user'
