import { useQuery } from 'react-query'
import {
  GET_RESTAURANT_INFO_KEY,
  getRestaurantInfo
} from '../api/getRestaurantInfo'
import { GetRestaurantInfoData } from '../types/RestaurantInfoData'

export function useGetRestaurantInfo(
  variables: { searchTerm: string },
  options?: {
    onError?: (err: unknown) => void
    onSuccess?: (data: GetRestaurantInfoData) => void
  }
) {
  const { data, isError, isLoading, refetch, status } = useQuery<
    GetRestaurantInfoData,
    Error
  >([GET_RESTAURANT_INFO_KEY, variables], () => getRestaurantInfo(variables), {
    ...options,
    enabled: false
  })

  return {
    data: data?.searchRestaurants,
    fetch: refetch,
    isError,
    isLoading,
    status
  }
}
