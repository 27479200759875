import { useState } from 'react'
import { Formik } from 'formik'
import { TextInputField, SubmitButton } from '@toasttab/buffet-pui-forms'
import { object, string } from 'yup'
import { useGetInvitationStatus } from '../hooks/useGetInvitationStatus'
import { useGetRestaurantInfo } from '../hooks/useGetRestaurantInfo'
import { RestaurantUserLookupStateContainer } from './RestaurantUserLookupStateContainer'

export const RestaurantUserLookup = ({ userGuid }: { userGuid: string }) => {
  const [restaurantGuid, setRestaurantGuid] = useState<string>('')

  const {
    data: invitationStatus,
    fetch: getInvitationStatus,
    status: getInvitationStatusStatus
  } = useGetInvitationStatus({
    userGuid,
    restaurantGuid
  })

  const {
    data: restaurantData,
    fetch: getRestaurantData,
    status: getRestaurantInfoStatus
  } = useGetRestaurantInfo({
    searchTerm: restaurantGuid
  })

  return (
    <>
      <Formik
        validationSchema={object().shape({
          restaurantGuid: string()
            .uuid('The restaurant GUID must be a valid UUID')
            .required('The restaurant GUID is required')
        })}
        initialValues={{ restaurantGuid: '' }}
        onSubmit={async ({ restaurantGuid }) => {
          await setRestaurantGuid(restaurantGuid)
          getInvitationStatus()
          getRestaurantData()
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className='flex flex-col md:flex-row justify-between px-1'
          >
            <TextInputField
              name='restaurantGuid'
              label={'Restaurant GUID'}
              containerClassName='w-full md:w-1/2 md:pr-3 pb-1 md:h-16'
            />
            <SubmitButton className='w-full mt-7 md:w-2/12 mb-6 md:0'>
              Lookup
            </SubmitButton>
          </form>
        )}
      </Formik>
      <RestaurantUserLookupStateContainer
        getRestaurantInfoStatus={getRestaurantInfoStatus}
        getInvitationStatusStatus={getInvitationStatusStatus}
        restaurantData={restaurantData}
        invitationStatus={invitationStatus}
        userGuid={userGuid}
      />
    </>
  )
}
